import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Container, Grid, H2, H4, H3, P } from "styles";
import { PostCard, SeoData } from "components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion";
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { Arrow, TwitterIcon, FacebookIcon, LinkedinIcon } from "../images/svgs";

const ArticleTemplate = ({ data, location }) => {
  const { frontmatter: article } = data.markdownRemark;
  const { href } = location;
  const [openReferences, setOpenReferences] = useState(true);
  console.log(article.mainImg)
  const image = getImage(article.mainImg);
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.25, duration: 0.75 }}
    >
      <SeoData
        pageSpecificTitle={article.title}
        pageSpecificDescription={article}
        pageSpecificThumbnail={article.mainImg}
        pageSpecificThumbnailAlt={article.title}
      />
      <Grid style={{ margin: "0 auto" }}>
        <ArticleContainer>
          <ArticleHeader>
            <div style={{ paddingRight: "1rem" }}>
              <H2>{article.title}</H2>
              <P dangerouslySetInnerHTML={{ __html: article.description }} />
            </div>
            <div style={{ position: "relative" }}>
              <GatsbyImage image={image} alt={article.title} />
            </div>
          </ArticleHeader>
          <SocialShare>
            <H3>Share on</H3>
            <SocialLinks>
              <LinkedinShareButton url={href}>
                <Social>
                  <LinkedinIcon />
                </Social>
              </LinkedinShareButton>
              <FacebookShareButton url={href}>
                <Social>
                  <FacebookIcon />
                </Social>
              </FacebookShareButton>
              <TwitterShareButton url={href}>
                <Social>
                  <TwitterIcon />
                </Social>
              </TwitterShareButton>
            </SocialLinks>
          </SocialShare>
          <Wrapper>
            <ArticleBody>
              <ArticleParagraphs>
                {article.content.map((section) => {
                  console.log(section);
                  return (
                    <div>
                      {section.image && (
                        <GatsbyImage
                          image={getImage(section.image)}
                          alt={section.title}
                        />
                      )}
                      <H4>{section.title}</H4>
                      <P
                        dangerouslySetInnerHTML={{ __html: section.paragraph }}
                      />
                    </div>
                  );
                })}
              </ArticleParagraphs>
              <CodeDateofPrep>{`${article.code} | ${article.dateOfPreparation}`}</CodeDateofPrep>
              <ReferenceButton
                type="button"
                onClick={() =>
                  setOpenReferences((prevOpenReferences) => !prevOpenReferences)
                }
                open={openReferences}
              >
                <Arrow
                  height="16px"
                  width="16px"
                  stroke={openReferences ? "#005A85" : "#F94C00"}
                />
                <P>References</P>
              </ReferenceButton>
              <AnimatePresence exitBeforeEnter>
                {article.references.length > 0 &&
                  openReferences &&
                  article.references.map((ref) => {
                    console.log(ref);
                    return (
                      <Reference
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        transition={{ duration: 0.375 }}
                      >
                        {ref.reference}
                      </Reference>
                    );
                  })}
              </AnimatePresence>
            </ArticleBody>
            <RelatedArticles>
              <P>Related articles</P>
              <AnimatePresence exitBeforeEnter>
                {article.relatedArticles.map((article, i) => (
                  <PostCard
                    key={i}
                    image={article.image}
                    title={article.name}
                    category={article.category}
                    description={article.description}
                    internalLink={article.internalLink}
                    link={article.link}
                    linkText="Read more"
                  />
                ))}
              </AnimatePresence>
            </RelatedArticles>
          </Wrapper>
        </ArticleContainer>
      </Grid>
    </motion.main>
  );
};

const ArticleContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  @media (min-width: 768px) {
    margin-bottom: 4rem;
    grid-column: 2/12;
  }
`;

const ArticleHeader = styled.div`
  display: flex;
  flex-direction: column-reverse;
  &div:first-of-type {
    display: flex;
    flex-direction: column;
  }
  div {
    width: 100%;
    height: auto;
  }
  p {
    line-height: 28px;
  }
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &div:first-of-type {
      display: flex;
      flex-direction: column;
    }
    div {
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        position: absolute !important;
        top: 0;
        left: 0;
      }
    }
  }
`;

const SocialShare = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0 2rem 0;
  width: 100%;
  ${H3} {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.125rem;
    margin-bottom: 1rem;
  }
  @media (min-width: 768px) {
    margin: 4rem 0;
  }
`;

const SocialLinks = styled.div`
  display: flex;
`;

const Social = styled.div`
  background: var(--primary-color);
  border-radius: 50%;
  min-width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  transition: 0.75s;
  &:first-of-type {
    margin-left: 0rem;
  }
  svg {
    max-width: 14px;
    max-height: 14px;
    transition: 0.75s;
    path {
      fill: white;
    }
  }
  :hover {
    background: var(--muted-color);
    svg {
      path {
        fill: var(--primary-color);
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 4rem;
    height: 100%;
    width: auto;
  }
`;

const ArticleBody = styled.div`
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const ArticleParagraphs = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.25rem;
  ${P} {
    font-size: 1rem;
    line-height: 1.5rem;
    sup {
      line-height: 0;
      vertical-align: super;
    }
  }
  .gatsby-image-wrapper {
    margin-top: 6.25rem;
    margin-bottom: 2.25rem;
  }
`;

const ReferenceButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  svg {
    transform: ${({ open }) => (open ? "rotate(-90deg)" : "rotate(0deg)")};
    transition: all 0.3s ease-in;
    color: ${({ open }) => (open ? "#005A85" : "#F94C00")};
    margin-right: 0.5rem;
  }
  ${P} {
    opacity: ${({ open }) => (open ? "0.5" : "1")};
    transition: all 0.3s ease-in;
    font-weight: 700;
    margin-bottom: 0px;
  }
`;

const Reference = styled(motion(P))`
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding-bottom: 0.5rem;
`;

const CodeDateofPrep = styled(P)`
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const RelatedArticles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 8rem;
  > * {
    margin-bottom: 1rem;
    max-height: 620px;
  }
`;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        id
        description
        slug
        title
        mainImg {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
          }
        }
        code
        dateOfPreparation
        content {
          contentOrder
          paragraph
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        references {
          reference
          referenceOrder
        }
        relatedArticles {
          category
          description
          id
          link
          name
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`;

export default ArticleTemplate;
